import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router";
import {useMediaQuery} from "react-responsive";

import BrandAndModelSearch from "../../components/ProductSearchField/BrandAndModelSearch";
import MultipleItemsSearchSelect from "../../components/ProductSearchField/MultipleItemsSearchSelect";
import RangeSelect from "../../components/ProductSearchField/RangeSelect";
import MenuCheckbox from "../../components/ProductMenu/MenuCheckbox";
import VehicleTypeSwitch from '../../components/ProductSearchField/VehicleTypeSwitch';

import {Drawer, Position} from "@blueprintjs/core";
import Grid from '@material-ui/core/Grid';

import {SearchOptionsContext} from "../../context/search-options-context";
import {CustomerTypeContext} from "../../context/customer-type-context";

import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import {TextField, Typography} from "@material-ui/core";
import FormattedDateInput from "../../components/FormattedDateInput/FormattedDateInput";

function FiltersDrawer(props) {

    let searchOptions = useContext(SearchOptionsContext);
    let searchOptionsForDeliveryCars = searchOptions.deliveryCars;
    let searchOptionsForPassengerCars = searchOptions.passengerCars;
    const [extended, setExtended] = useState(false);
    const [customerType] = useContext(CustomerTypeContext);

    const isMobile = useMediaQuery({query: '(max-width: 660px)'});

    const calculationTypeNames = {
        LEASING: "Leasing",
        RENTAL: "Wynajem",
        CREDIT: "Kredyt"
    }

    useEffect(() => {
        props.setSearchParameters(URLSearchParamsConverter.getSearchParamsFromURLSearchString(props.location.search, searchOptions));
    },[searchOptions]);

    useEffect(() => {
        let currentSearchParams = new URLSearchParams(props.location.search);
        if(currentSearchParams.get('extended') === '1') {
            setExtended(true);
        }
    }, [])

    const changeSpecialOfferHandler = (event) => {
        props.setSearchParameters(prevSearchParameters => ({...prevSearchParameters, specialOffer: event.target.checked ? true : null}));
    };

    const changeIsPremiumHandler = (event) => {
        props.setSearchParameters(prevSearchParameters => ({...prevSearchParameters, isPremium: event.target.checked ? true : null}));
    };

    const changeAvailabilityOnStockHandler = (event) => {
        props.setSearchParameters(prevSearchParameters => ({
            ...prevSearchParameters,
            availability: event.target.checked ? "IN_STOCK" : null
        }));
    };

    const changeBudgetHandler = (budget) => {
        if (customerType === searchOptions.customerTypes[0]) {
            props.setSearchParameters(prevSearchParameters =>
                ({
                    ...prevSearchParameters,
                    installmentNetFrom: budget.from,
                    installmentNetTo: budget.to,
                    installmentGrossFrom: null,
                    installmentGrossTo: null
                }));
        } else {
            props.setSearchParameters(prevSearchParameters =>
                ({
                    ...prevSearchParameters,
                    installmentGrossFrom: budget.from,
                    installmentGrossTo: budget.to,
                    installmentNetFrom: null,
                    installmentNetTo: null,
                }));
        }
    };

    const changeMinimumPriceHandler = (minimumPrice) => {
        if(customerType === searchOptions.customerTypes[0]) {
            props.setSearchParameters(prevState => ({
                ...prevState,
                minimumSellPriceNetFrom: minimumPrice.from,
                minimumSellPriceNetTo: minimumPrice.to,
                minimumSellPriceGrossFrom: null,
                minimumSellPriceGrossTo: null
            }));
        } else {
            props.setSearchParameters(prevState => ({
                ...prevState,
                minimumSellPriceNetFrom: null,
                minimumSellPriceNetTo: null,
                minimumSellPriceGrossFrom: minimumPrice.from,
                minimumSellPriceGrossTo: minimumPrice.to
            }));
        }
    }

    const changeEnginePowerHandler = (power) => {
        props.setSearchParameters(prevSearchParameters =>
                ({
                    ...prevSearchParameters,
                    enginePowerHpFrom: power.from,
                    enginePowerHpTo: power.to,
                }));
    };

    const changePropertyHandler = (propertyName, value) => {
        props.setSearchParameters(prevSearchParameters => {
                let searchParams = prevSearchParameters;
                searchParams[propertyName] = value;

                return searchParams;
            }
        );
    };

    const changeMileageHandler = (mileage) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                mileageFrom: mileage.from,
                mileageTo: mileage.to,
            }));
    };

    const changeManufactureYearHandler = (manufactureYear) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                manufactureYearFrom: manufactureYear.from,
                manufactureYearTo: manufactureYear.to,
            }));
    };

    const changeNipHandler = (event) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                nip: event.target.value
            }));
    };

    const changeGuardianCodeHandler = (event) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                guardianCode: event.target.value
            }));
    };

    const changeCreatedFromHandler = (event) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                createdFrom: event !== null ? (event.toISOString().split('T')[0]) : ""
            }));
    };
    const changeCreatedToHandler = (event) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                createdTo: event !== null ? (event.toISOString().split('T')[0]) : ""
            }));
    }

    const searchButtonClicked = () => {
        props.history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(props.searchParameters, "", extended)
        });

        props.onClose();
    }

    const clearButtonClicked = () => {
        props.setSearchParameters(props.initialState);

        if(customerType === 'COMPANY') {
            props.history.push({
                search: '?vehicleTypes=OSOBOWE&customerType=COMPANY'
            });
        } else if(customerType === 'PERSON') {
            props.history.push({
                search: '?vehicleTypes=OSOBOWE&customerType=PERSON'
            });
        }
    }

    const vehicleTypeHandler = (type) => {
        props.setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                vehicleTypes: type,
            }));
    }

    let installmentRange = {from: props.searchParameters.installmentNetFrom, to: props.searchParameters.installmentNetTo};
    let sellPriceRange = {from: props.searchParameters.minimumSellPriceNetFrom, to: props.searchParameters.minimumSellPriceNetTo};
    let engineHpPowerRange = {from: props.searchParameters.enginePowerHpFrom, to: props.searchParameters.enginePowerHpTo};
    let manufactureYearRange = {from: props.searchParameters.manufactureYearFrom, to: props.searchParameters.manufactureYearTo};
    let mileageRange = {from: props.searchParameters.mileageFrom, to: props.searchParameters.mileageTo};

    if(customerType !== searchOptions.customerTypes.company){
        installmentRange = {from: props.searchParameters.installmentGrossFrom, to: props.searchParameters.installmentGrossTo};
        sellPriceRange = {from: props.searchParameters.minimumSellPriceGrossFrom, to: props.searchParameters.minimumSellPriceGrossTo};
    }

    if(props.searchParameters.vehicleTypes==="DOSTAWCZE") {
        searchOptions = searchOptionsForDeliveryCars;
    }
    if(props.searchParameters.vehicleTypes==="OSOBOWE") {
        searchOptions = searchOptionsForPassengerCars;
    }

    return (
        <Drawer
            {...props}
            size={isMobile ? "80%" : "650px"}
            position={Position.LEFT}
            canOutsideClickClose={true}>

            <div className="offer-list-drawer">
                <MenuCheckbox
                    name="availabilityInStock"
                    checked={props.searchParameters.availability === "IN_STOCK"}
                    selected={changeAvailabilityOnStockHandler}
                >Dostępne "od ręki"</MenuCheckbox>

                <MenuCheckbox
                    name="specialOffers"
                    checked={props.searchParameters.specialOffer}
                    selected={changeSpecialOfferHandler}
                >Oferty specjalne (Hot Oferta)</MenuCheckbox>

                <MenuCheckbox
                    name="isPremium"
                    checked={props.searchParameters.isPremium}
                    selected={changeIsPremiumHandler}
                >Wyświetl tylko premium</MenuCheckbox>

                <br/>

                <VehicleTypeSwitch
                    value={props.searchParameters.vehicleTypes}
                    onChange={vehicleTypeHandler}/>

                <Grid container spacing={1}>

                    <Grid item xs={12} sm={6}>
                        <BrandAndModelSearch
                            brandsAndModels={searchOptions.brandsAndModels}
                            selectedBrands={props.searchParameters.brands}
                            selectedModels={props.searchParameters.models}
                            resetBrands={props.searchParameters.brands.length === 0}
                            onBrandChange={(brands) => changePropertyHandler("brands", brands)}
                            onModelChange={(models) => changePropertyHandler("models", models)}
                            placeholderForBrands="Marka"
                            placeholderForModelss="Model"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.bodyTypes}
                            selectedItems={props.searchParameters.bodyTypes}
                            resetSelected={props.searchParameters.bodyTypes.length === 0}
                            onChange={(bodyTypes) => changePropertyHandler("bodyTypes", bodyTypes)}
                            placeholder="Nadwozie"/>

                        <MultipleItemsSearchSelect
                            items={searchOptions.segments}
                            selectedItems={props.searchParameters.segments}
                            resetSelected={props.searchParameters.segments.length === 0}
                            onChange={(segments) => changePropertyHandler("segments", segments)}
                            placeholder="Segment (typ)"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RangeSelect
                            onChange={changeBudgetHandler}
                            items={searchOptions.installments}
                            range={installmentRange}
                            unit="zł"
                            placeholder={["Rata od", "Rata do"]}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RangeSelect
                            onChange={changeManufactureYearHandler}
                            items={searchOptions.manufactureYears}
                            range={manufactureYearRange}
                            reverseValuesList
                            unit=""
                            placeholder={["Rok produkcji od", "Rok produkcji do"]}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RangeSelect
                            onChange={changeMinimumPriceHandler}
                            items={searchOptions.prices}
                            range={sellPriceRange}
                            unit="zł"
                            placeholder={["Cena od", "Cena do"]}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RangeSelect
                            onChange={changeMileageHandler}
                            items={searchOptions.mileages}
                            range={mileageRange}
                            unit="km"
                            placeholder={["Przebieg od", "Przebieg do"]}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.fuelTypes}
                            selectedItems={props.searchParameters.fuelTypes}
                            resetSelected={props.searchParameters.fuelTypes.length === 0}
                            onChange={(fuelTypes) => changePropertyHandler("fuelTypes", fuelTypes)}
                            placeholder="Paliwo"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RangeSelect
                            onChange={changeEnginePowerHandler}
                            items={searchOptions.enginePowerHp}
                            range={engineHpPowerRange}
                            unit="KM"
                            placeholder={["Moc silnika od", "Moc silnika do"]}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.gearboxTypes}
                            selectedItems={props.searchParameters.gearboxTypes}
                            resetSelected={props.searchParameters.gearboxTypes.length === 0}
                            onChange={(gearboxTypes) => changePropertyHandler("gearboxTypes", gearboxTypes)}
                            placeholder="Skrzynia biegów"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.driveTypes}
                            selectedItems={props.searchParameters.driveTypes}
                            resetSelected={props.searchParameters.driveTypes.length === 0}
                            onChange={(driveTypes) => changePropertyHandler("driveTypes", driveTypes)}
                            placeholder="Napęd"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.colors}
                            selectedItems={props.searchParameters.colors}
                            resetSelected={props.searchParameters.colors.length === 0}
                            onChange={(colors) => changePropertyHandler("colors", colors)}
                            placeholder="Kolor nadwozia"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.numbersOfDoors.map(item => (item.toString()))}
                            selectedItems={props.searchParameters.numberOfDoors}
                            resetSelected={props.searchParameters.numberOfDoors.length === 0}
                            onChange={(numberOfDoors) => changePropertyHandler("numberOfDoors", numberOfDoors)}
                            placeholder="Liczba drzwi"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.numbersOfSeats.map(item => (item.toString()))}
                            selectedItems={props.searchParameters.numberOfSeats}
                            resetSelected={props.searchParameters.numberOfSeats.length === 0}
                            onChange={(numberOfSeats) => changePropertyHandler("numberOfSeats", numberOfSeats)}
                            placeholder="Liczba siedzeń"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.equipment.map(item => (item.toString()))}
                            selectedItems={props.searchParameters.equipment}
                            resetSelected={props.searchParameters.equipment.length === 0}
                            onChange={(equipment) => changePropertyHandler("equipment", equipment)}
                            placeholder="Wyposażenie"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MultipleItemsSearchSelect
                            items={searchOptions.calculationTypes}
                            selectedItems={props.searchParameters.calculationTypes}
                            resetSelected={props.searchParameters.calculationTypes.length === 0}
                            onChange={(calculationTypes) => changePropertyHandler("calculationTypes", calculationTypes)}
                            placeholder="Forma finansowania"/>
                    </Grid>

                    { extended &&
                        <React.Fragment>
                            <Grid item xs={12} sm={16}>
                                <Typography variant="p" sx={{mt:5,mb:2}}>Rozszerzone filtry</Typography>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={props.searchParameters.nip}
                                    onChange={changeNipHandler}
                                    placeholder="Nip dostawcy"
                                    variant="outlined"
                                    size="small"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={props.searchParameters.guardianCode}
                                    onChange={changeGuardianCodeHandler}
                                    placeholder="Kod opiekuna"
                                    variant="outlined"
                                    size="small"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MultipleItemsSearchSelect
                                    items={['VEHIS', 'ADMIN', 'AKOL', 'MAZUREK']}
                                    selectedItems={props.searchParameters.sources}
                                    resetSelected={props.searchParameters.sources.length === 0}
                                    onChange={(sources) => changePropertyHandler("sources", sources)}
                                    placeholder="Źródło ofert"/>
                            </Grid>
                            <Grid item xs={12} sm={6} className="filtersDateInput">
                                <FormattedDateInput
                                    name="createdFrom"
                                    showOnlyPast={true}
                                    value={props.searchParameters.createdFrom != "" && props.searchParameters.createdFrom != null ? new Date(props.searchParameters.createdFrom) : null}
                                    onChange={changeCreatedFromHandler}
                                    placeHolder="Data utworzenia od"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="filtersDateInput">
                                <FormattedDateInput
                                    name="createdTo"
                                    showOnlyPast={false}
                                    value={props.searchParameters.createdTo != "" && props.searchParameters.createdTo != null ? new Date(props.searchParameters.createdTo) : null}
                                    onChange={changeCreatedToHandler}
                                    placeHolder="Data utworzenia do"

                                />
                            </Grid>
                        </React.Fragment>

                    }

                </Grid>

                <button type="button" onClick={searchButtonClicked}>Zastosuj filtr</button>
                <button className="searchButton" type="button" onClick={clearButtonClicked}>Wyczyść</button>

            </div>
        </Drawer>
    );
}

export default withRouter(FiltersDrawer);